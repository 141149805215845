





























































































import { computed, defineComponent, ref, watch } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import { MeetingContent, Agenda } from "@/models/Meeting"
import { InputValidationRule } from "vuetify"

export default defineComponent({
  props: {
    value: {
      type: MeetingContent,
    },
  },
  components: {
    I18nFormattedMessage,
    TextField,
  },

  setup(props, { emit }) {
    const isValid = ref(false)
    const form = ref<{ validate: () => void }>()

    const meetingContent = computed({
      get: () => props.value,
      set: newValue => {
        emit("input", newValue)
      },
    })

    // 最後の行がクリックされたら議題追加
    const addAgenda = (index: number) => {
      if (
        meetingContent.value?.agendas &&
        index === meetingContent.value.agendas.length - 1
      ) {
        meetingContent.value.agendas.push(new Agenda())
        emit("input", meetingContent.value)
      }
    }

    const totalTimes = computed(() => {
      return (
        props.value?.agendas?.reduce((a, agenda) => {
          return a + (parseInt(`${agenda.times || 0}`, 10) ?? 0)
        }, 0) || 0
      )
    })

    const getPlaceholder = (index: number) => {
      if (index === 0) return "会議の目的・ゴール・役割の共有"
      else if (
        meetingContent.value?.agendas &&
        index === meetingContent.value.agendas.length - 1
      )
        return "まとめ"
      else if (index === 1) return "活動結果の報告"
      else if (index === 2)
        return "【営業部週次定例会】各メンバーの活動結果と予定の共有"
      else return ""
    }

    const deleteAgenda = (index: number) => {
      if (
        meetingContent.value?.agendas &&
        meetingContent.value.agendas.length > 1
      ) {
        meetingContent.value.agendas.splice(index, 1)
        emit("input", meetingContent.value)
      }
    }

    const rules: { [name: string]: InputValidationRule } = {
      onlyNumber: (value: string) => {
        const regexp = new RegExp(/^([1-9]\d*|0)$/)
        if (!value) return true
        if (!regexp.test(value)) return "有効な数字を入力してください。"
        else return true
      },
    }

    const Input = () => {
      emit("input", meetingContent.value)
    }

    watch(
      () => isValid.value,
      () => {
        emit("valid", isValid.value)
      }
    )

    return {
      isValid,
      form,
      meetingContent,
      addAgenda,
      getPlaceholder,
      deleteAgenda,
      rules,
      Input,
      totalTimes,
    }
  },
})
