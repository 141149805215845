









































































































































import { defineComponent, reactive, ref } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import Calendar from "./Calendar.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { Agenda, Meeting, MeetingContent } from "@/models/Meeting"
import User from "@/models/User"
import DateTime from "@/models/DateTime"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { Event, DateTimeTimeZone } from "@microsoft/microsoft-graph-types"
import { alertContainer } from "@/containers/AlertContainer"
import ReadonlyTextField from "./ReadonlyTextField.vue"
import AgendaInput from "@/views/NewMeetingCreation/AgendaInput.vue"
import { SanitizeHtml } from "@/utilities/SanitizeHtml"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import { uniqueArray } from "@/utilities/UniqueArray"

interface State {
  isLoading: boolean
  repeat: boolean
  isFormValid: boolean
  isOpenDialog: boolean
  selectedEvent: Event | null
  contents: MeetingContent
}

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    I18nFormattedMessage,
    TextField,
    DefaultDialog,
    WithBackPageLayout,
    Calendar,
    ReadonlyTextField,
    AgendaInput,
  },
  setup(props) {
    const state = reactive<State>({
      isLoading: false,
      repeat: false,
      isFormValid: false,
      isOpenDialog: false,
      selectedEvent: null,
      contents: new MeetingContent({
        purpose: "",
        goal: "",
        agendas: [new Agenda(), new Agenda()],
      }),
    })
    const form = ref<{ validate: () => void }>()

    const { getContext, getMembers } = teamsContextContainer.useContainer()
    const {
      getMeetingsAsync,
      createMeetingAsync,
      getRepeatEventsAsync,
    } = meetingContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()

    const onFormValidityChanged = (isValid: boolean) => {
      state.isFormValid = isValid
    }

    const sanitize = (html: string) => {
      return SanitizeHtml(html)
    }

    const getDisplyDateTime = (
      startBase: DateTimeTimeZone,
      endBase: DateTimeTimeZone
    ) => {
      const start = new DateTime(
        startBase?.dateTime,
        startBase?.timeZone || undefined
      )._value.toDate()
      const end = new DateTime(
        endBase?.dateTime,
        startBase?.timeZone || undefined
      )._value.toDate()
      const date = new DateTime(start).toDateJpString()
      const dateTime = `${date} ${new DateTime(
        start
      ).toJpTimeString()} ~ ${new DateTime(end).toJpTimeString()}`

      return dateTime
    }

    const createMeeting = async (
      siteId: string,
      entityId: string,
      event: Event
    ) => {
      const members = await getMembers()
      //開催者と参加者をマージする。重複している場合は取り除く
      const attendeesAddress = uniqueArray(
        u => {
          if (!u || !u.address) {
            return ""
          } else {
            return u.address
          }
        },
        event.attendees
          ?.filter(u => u.type !== "resource")
          ?.map(u => u.emailAddress) ?? [],
        [event.organizer?.emailAddress] ?? []
      )
      const meeting = new Meeting({
        iCaluid: event.iCalUId,
        isTeams: event.isOnlineMeeting,
        subject: event.subject ?? "(件名なし)",
        location: event.location?.displayName,
        startTime: new DateTime(
          event.start?.dateTime,
          event.start?.timeZone || undefined
        ),
        endTime: new DateTime(
          event.end?.dateTime,
          event.end?.timeZone || undefined
        ),
        users: attendeesAddress?.map(a => {
          const user = members.find(u => u.email === a?.address)
          return new User({
            id: user?.id,
            displayName: user?.displayName || a?.name,
            email: a?.address,
            userId: user?.userId,
          })
        }),
        purpose: state.contents.purpose,
        goal: state.contents.goal,
        agendas: state.contents.agendas?.filter(a => a.agenda) ?? [],
      } as Meeting)
      await createMeetingAsync(siteId, entityId, props.id, meeting, false)
    }

    const onCancel = () => {
      state.isOpenDialog = false
    }

    const onOk = async () => {
      if (!state.selectedEvent) {
        return
      }
      state.isLoading = true
      const targetEvents: Array<Event> = []
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      if (state.repeat) {
        const start = new DateTime(state.selectedEvent.start?.dateTime)
        const meetingStructures = await getMeetingsAsync(context.entityId)
        const meetingICaluids = meetingStructures.reduce((m, c) => {
          m.push(...c.meetings.map(m => m.iCaluid))
          return m
        }, [] as Array<string>)
        // 既に登録済みの会議は取り除く
        const target = (
          await getRepeatEventsAsync(
            state.selectedEvent.seriesMasterId || "",
            start,
            start.add({ months: 6 })
          )
        ).filter(ev => !meetingICaluids.some(m => m === ev.iCalUId))
        targetEvents.push(...target)
      } else {
        targetEvents.push(state.selectedEvent)
      }
      try {
        await Promise.all(
          targetEvents.map(target =>
            createMeeting(project.siteId, context.entityId, target)
          )
        )
        showSuccessMessage("会議を作成しました")
      } finally {
        state.isLoading = false
      }
      goToRoute({
        name: RouteName.MeetingSeries,
        params: {
          id: props.id,
        },
      })
      state.isOpenDialog = false
    }

    const openDialog = (event: Event, contents?: MeetingContent) => {
      state.repeat = !!event.seriesMasterId
      let agendas = [new Agenda(), new Agenda()]
      if (contents?.agendas && contents.agendas.length > 0) {
        agendas = contents.agendas
      }
      state.contents = new MeetingContent({
        purpose: contents?.purpose || "",
        goal: contents?.goal || "",
        agendas: agendas,
      })
      state.isOpenDialog = true
      state.selectedEvent = event
    }

    // 最後の行がクリックされたら議題追加
    const addAgenda = (index: number) => {
      if (
        state.contents.agendas &&
        index === state.contents.agendas.length - 1
      ) {
        state.contents.agendas.push(new Agenda())
      }
    }

    const getPlaceholder = (index: number) => {
      if (index === 0) return "会議の目的・ゴール・役割の共有"
      else if (index === (state.contents.agendas?.length ?? 0) - 1)
        return "まとめ"
      else if (index === 1) return "活動結果の報告"
      else if (index === 2)
        return "【営業部週次定例会】各メンバーの活動結果と予定の共有"
      else return ""
    }

    const deleteAgenda = (index: number) => {
      if (state.contents.agendas) {
        state.contents.agendas.splice(index, 1)
      }
    }

    return {
      state,
      form,
      openDialog,
      getDisplyDateTime,
      onFormValidityChanged,
      sanitize,
      onCancel,
      onOk,
      addAgenda,
      deleteAgenda,
      getPlaceholder,
    }
  },
})
