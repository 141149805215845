/**
 * itemからハッシュを生成するファンクション
 */
type HashGenerator<T> = (item: T) => string

export { uniqueArray }

/**
 * arrsをマージして重複しているアイテムを除外した配列を返します。
 *
 * @param {HashGenerator<ItemType>} hashGenerator アイテムを比べる時に利用する文字列を生成するファンクション
 * @param {...ItemType[]} arrs チェックする配列
 * @returns {ItemType[]} 重複しているアイテムを除外した配列
 */
function uniqueArray<ItemType>(
  hashGenerator: HashGenerator<ItemType>,
  ...arrs: Array<Array<ItemType>>
): Array<ItemType> {
  if (arrs.length < 1) return []

  const items: Record<string, Array<ItemType>> = {}

  arrs.forEach(arr => {
    arr.forEach(item => {
      const hash = hashGenerator(item)
      items[hash] = items[hash] || []
      items[hash].push(item)
    })
  })

  return Object.values(items).map(i => i[0])
}
